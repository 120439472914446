<template>
  <div class="main">
    <!--        <button class="first" @click="First" v-bind:disabled="a">{{first}}</button>-->
    <button class="prev" @click="Prev" :class="{ fenye_right_none: b }" v-bind:disabled="b">
      <img class="img_left" :src="b ? 'https://cdn.qingmeidata.com/d28e1c10.png' : 'https://cdn.qingmeidata.com/cbe009bd.png'" />
    </button>
    <div v-if="li <= 5" class="rowSC">
      <button v-for="(item, i) in list" :key="i" @click="buttonPage(item)" :class="{ xuan: currentPage == item }">{{ item }}</button>
    </div>
    <div v-else>
      <div v-if="currentPage <= 3" class="rowSC">
        <button v-for="(item, i) in list" :key="i" @click="buttonPage(item)" :class="[{ li_hide: item > 5 }, { xuan: currentPage == item }]">
          {{ item }}
        </button>
      </div>
      <div v-else-if="currentPage > 3 && li - currentPage >= 2" class="rowSC">
        <button
          v-for="(item, i) in list"
          :key="i"
          @click="buttonPage(item)"
          :class="[{ li_hide: item < currentPage - 2 || item > currentPage + 2 }, { xuan: currentPage == item }]"
        >
          {{ item }}
        </button>
      </div>
      <div v-else-if="li - currentPage < 2">
        <div v-if="li - currentPage == 1" class="rowSC">
          <button v-for="(item, i) in list" :key="i" @click="buttonPage(item)" :class="[{ li_hide: item < currentPage - 3 }, { xuan: currentPage == item }]">
            {{ item }}
          </button>
        </div>
        <div v-else-if="li - currentPage == 0" class="rowSC">
          <button v-for="(item, i) in list" :key="i" @click="buttonPage(item)" :class="[{ li_hide: item < currentPage - 4 }, { xuan: currentPage == item }]">
            {{ item }}
          </button>
        </div>
      </div>
    </div>
    <button class="next" :class="{ fenye_right_none: c }" @click="Next" :disabled="c">
      <img class="img_right" :src="c ? 'https://cdn.qingmeidata.com/d28e1c10.png' : 'https://cdn.qingmeidata.com/cbe009bd.png'" />
    </button>
    <!-- <span class="txt">{{ $t('Total') }} {{ li }} {{ $t('Pages Go to Page') }} </span> -->
    <!--        <button class="last" @click="Last" :disabled="d">{{last}}</button>-->
    <!-- <input :value="value" @input="change" />
        <button @click="just">{{ $t('GO') }}</button> -->
    <!--        <button  @click="just">跳转</button>-->
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    data() {
      return {
        // name:"哈哈子"
        first: '首页',
        last: '尾页',
        prev: '上一页',
        next: '下一页',
        a: false,
        b: false,
        c: false,
        d: false,
        page: 1,
        pageSize: 10,
        total: 0, //总数
        list: [], //页数
        currentPage: 1, //当前页
        li: 1,
        value: 1,
        input: 0
      }
    },
    props: ['config'],
    created() {
      this.total = this.config.total
      this.pageSize = this.config.pageSize
      this.currentPage = this.config.currentPage || 1
      this.li = Math.ceil(this.total / this.pageSize)
      for (var i = 1; i <= this.li; i++) {
        this.list.push(i)
      }
      this.value = this.currentPage
      this.input = this.currentPage
      if (this.currentPage == 1 && this.currentPage != this.li) {
        this.a = true
        this.b = true
        this.c = false
        this.d = false
      } else if (this.currentPage == 1 && this.currentPage == this.li) {
        this.a = true
        this.b = true
        this.c = true
        this.d = true
      } else if (this.currentPage > 1 && this.currentPage == this.li) {
        this.a = false
        this.b = false
        this.c = true
        this.d = true
      } else {
        this.a = false
        this.b = false
        this.c = false
        this.d = false
      }
    },
    methods: {
      change(val) {
        this.input = parseInt(val.target.value)
      },
      just() {
        if (this.input <= this.li && this.input > 0 && typeof this.input === 'number') {
          this.buttonPage(this.input)
        } else {
          this.value = ''
        }
      },
      First() {
        // // console.log("First")
        this.buttonPage(1)
      },
      Last() {
        // // console.log("Last")
        this.buttonPage(this.li)
      },
      Prev() {
        // // console.log("Prev")
        if (this.currentPage > 1) {
          this.buttonPage(this.currentPage - 1)
        }
      },
      Next() {
        // // console.log("Next")
        if (this.currentPage < this.li) {
          this.buttonPage(this.currentPage + 1)
        }
      },
      buttonPage(val) {
        // // console.log(val)
        if (val == 1 && val != this.li) {
          this.a = true
          this.b = true
          this.c = false
          this.d = false
        } else if (val == 1 && val == this.li) {
          this.a = true
          this.b = true
          this.c = true
          this.d = true
        } else if (this.li > 1 && val == this.li) {
          this.a = false
          this.b = false
          this.c = true
          this.d = true
        } else {
          this.a = false
          this.b = false
          this.c = false
          this.d = false
        }
        this.value = val
        this.currentPage = val
        this.input = val
        this.$emit('currentPage', val)
        //ajax请求数据
      }
    }
  }
</script>

<style lang="less" scoped>
  .main {
    height: 50px;
    display: flex;
    align-items: center;
    input {
      width: 50px;
      height: 36px;
      border: 1px solid #ccc;
      margin-right: 5px;
      text-align: center;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      line-height: 20px;
      padding: 9px;
      background: #f0f1f1;
      color: #606266;
      border-radius: 4px;
      margin-right: 8px;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
    }
    hover {
      cursor: pointer;
    }
    .xuan {
      background: var(--Zcolor);
      border: 1px solid var(--Zcolor);
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .li_hide {
      display: none;
    }
  }

  .img_left {
    width: 14px;
    height: 14px;
  }
  .img_right {
    width: 14px;
    height: 14px;
    transform: rotate(180deg);
  }
  .txt {
    float: left;
    line-height: 36px;
    font-size: 14px;
    margin: 0 10px 0 5px;
  }
  .fenye_right_none {
    color: #c0c4cc !important;
  }
  .fenye_right_none:hover {
    cursor: no-drop !important;
  }
</style>
